// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-pages-warranty-claim-index-js": () => import("./../../../src/pages/warrantyClaim/index.js" /* webpackChunkName: "component---src-pages-warranty-claim-index-js" */),
  "component---src-pages-warranty-index-js": () => import("./../../../src/pages/warranty/index.js" /* webpackChunkName: "component---src-pages-warranty-index-js" */),
  "component---src-pages-warranty-registration-index-js": () => import("./../../../src/pages/warrantyRegistration/index.js" /* webpackChunkName: "component---src-pages-warranty-registration-index-js" */)
}

